import React from 'react';
import { Helmet } from 'react-helmet';
import ArticlesList from '../components/ArticlesList';

const Blog = () => {

    return (
        <section className="blog">
            <Helmet>
                <title>Blog - DigitalComInfluence | Articles sur le Monde du Digital et des Nouvelles Technologies</title>
                <meta name="description" content="Explorez les articles de mon blog couvrant divers sujets liés au monde du digital, aux nouvelles technologies, au web et à l'innovation !" />
            </Helmet>
            <div className="container">
                <h1>Bienvenue sur le blog - DigitalComInfluence</h1>
                <h2>Découvrez chaque semaine un nouvel article sur les nouvelles technologies, et le monde digital !</h2>
            </div>
            <ArticlesList />
        </section>
    );
};

export default Blog;