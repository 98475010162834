import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {NavLink} from 'react-router-dom';
import DeleteArticle from '../../components/admin/DeleteArticle';
import {Helmet} from 'react-helmet';

const ArticlesList = () => {
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);
    const articlesPerPage = 6;
    const [upArticle, setUpArticle] = useState(false);
    const [resetArticles, setResetArticle] = useState(false);

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?getAllArticles=true`)
            .then(response => {
                setArticles(response.data);
                setUpArticle(false)
                setResetArticle(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [upArticle, resetArticles]);

    const getPaginatedArticles = () => {
        const startIndex = (page - 1) * articlesPerPage;
        const endIndex = startIndex + articlesPerPage;
        return articles.slice(startIndex, endIndex);
    };

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);
        if (searchValue === "") {
            setResetArticle(true);
        }
        const filteredResults = articles.filter((article) =>
            article.title.toLowerCase().includes(searchValue.toLowerCase())
        );

        setArticles(filteredResults);
    };
    return (
        <div className="container articles_list">
            <Helmet>
                <title>Listes des articles - DigitalComInfluence</title>
                <meta name="robots" content="noindex, nofollow"/>
            </Helmet>
            <div className="search_article">
                <p>Rechercher un article</p>
                <input type="text" onChange={handleSearch} value={searchTerm} placeholder='Mots clé...'/>
            </div>
            <section className="articles">
                {
                    articles.length > 0 ?
                        getPaginatedArticles().map((item, i) => (
                            <article key={i}>
                                <figure>
                                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                    <img src={item.image} alt={`Image de l'article ${item.title}`}/>
                                </figure>
                                <h3>{item.title}</h3>
                                <h4 style={{color: "red"}}>Nombre de vues : {item.views / 2}</h4>
                                {/* <p>{item.content.substring(0, 200)} ...</p> */}
                                <NavLink className='button'
                                         to={`/blog/article/${item.title.toLowerCase().replaceAll(/ /g, '-').replaceAll(/[^\w-]+/g, '')}/${item.id}`}>Voir
                                    l'article</NavLink>
                                <DeleteArticle article_id={item.id} article_title={item.title}
                                               setUpArticle={setUpArticle}/>
                                <NavLink to={`/admin/blog/modifier-article/${item.id}`}>Modifier l'article</NavLink>
                            </article>
                        ))
                        : <p>Aucuns articles pour le moment !</p>
                }
            </section>
            {
                articles.length > 6 ?
                    <div className="pagination">
                        <button onClick={() => setPage(page - 1)} disabled={page === 1}>Précédent</button>
                        <span>Page {page}</span>
                        <button onClick={() => setPage(page + 1)}
                                disabled={page * articlesPerPage >= articles.length}>Suivant
                        </button>
                    </div>
                    : null
            }
        </div>
    );
};

export default ArticlesList;