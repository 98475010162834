import React, {useEffect, useState} from 'react';
import axios from 'axios';
import DeleteNewsletter from '../../components/admin/DeleteNewsletter';

const NewsLetterList = () => {
    const [newsletters, setNewsletters] = useState([]);
    const [resetNewsletter, setResetNewsletter] = useState(false);
    const [deleteResponse, setDeleteResponse] = useState('');
    const [search, setSearch] = useState('');
    const [newsletterFiltered, setNewsletterFiltered] = useState(newsletters);

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?getAllNewsLetters=true`)
            .then(response => {
                setNewsletters(response.data);
                setNewsletterFiltered(response.data);
                setResetNewsletter(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [resetNewsletter]);

    const searchFilter = (newsletters) => {
        const filteredEmail = newsletters.filter((newsletter) => {
            return newsletter.email.toLowerCase().includes(search.toLowerCase());
        });
        setNewsletterFiltered(filteredEmail);
    }

    return (
        <section className="newsletter_list container">
            <h1>Listes de e-mails inscrit à la newsletter</h1>
            {
                deleteResponse ?
                    <p style={{color: 'red', textAlign: 'center'}}>{deleteResponse}</p>
                    : null
            }

            <div className="search">
                <input type="text" placeholder="Rechercher un e-mail" onChange={(e) => {
                    setSearch(e.target.value);
                    searchFilter(newsletters);
                }}/>
            </div>

            <table>
                <thead>
                <tr>
                    <th>e-mail</th>
                    <th>Date d'inscription</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    newsletterFiltered.map((item, i) => (
                        <tr key={i}>
                            <td>{item.email}</td>
                            <td>{item.subscribe_at}</td>
                            <DeleteNewsletter id={item.id} setResetNewsletter={setResetNewsletter} email={item.email}
                                              setDeleteResponse={setDeleteResponse}/>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </section>
    );
};

export default NewsLetterList;