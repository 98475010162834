import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Contact = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [title, setTitle] = useState('');
    const [budget, setBudget] = useState('');
    const [message, setMessage] = useState('');
    const [rgpd, setRgpd] = useState(false);
    const [bait, setBait] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    return (
        <section className="contact">
            <Helmet>
                <title>Contactez DigitalComInfluence - Contact | Votre Community Manager à l'écoute</title>
                <meta name="description" content="Vous avez besoin d'un Community Manager compétent ? DigitalComInfluence est là pour vous ! N'hésitez pas à me contacter pour toute demande. Je suis déterminée à répondre au mieux à vos besoins et attentes." />
            </Helmet>
            <div className="container">
                <div className="contact-head">
                    <h1>DigitalComInfluence - Community Manager Freelance</h1>
                    <h2>Et si on allait plus loin ? </h2>
                </div>
                <div className="contact-methods">
                    <p>Si vous avez une question ou que vous désirez un devis, n'hésitez pas à me contacter.</p>
                    <div className="contact-methods-items">
                        <a href="tel:0667365028">
                            <article>
                                <figure>
                                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/messages.svg" alt="Illustration d'un téléphone" />
                                </figure>
                                <h3>Par SMS</h3>
                            </article>
                        </a>
                        <a href="https://calendly.com/digitalcominfluence" target='_blank' rel="noreferrer" >
                            <article>
                                <figure>
                                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/conference.svg" alt="Illustration d'une conférence" />
                                </figure>
                                <h3>En visio</h3>
                            </article>
                        </a>
                        <a href="tel:0667365028">
                            <article>
                                <figure>
                                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/contact.svg" alt="Illustration d'une conversation téléphonique" />
                                </figure>
                                <h3>Par téléphone</h3>
                            </article>
                        </a>
                        <a href="mailto:digitalcominfluence@gmail.com">
                            <article>
                                <figure>
                                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/email.svg" alt="Illustration d'un e-mail" />
                                </figure>
                                <h3>Par e-mail</h3>
                            </article>
                        </a>
                    </div>
                </div>
                <div className="contact-form">
                    <h2>Vous pouvez aussi remplir le formulaire ci-dessous</h2>
                    {
                        error ?
                            <div className="form-error">
                                <p>{error}</p>
                            </div>
                            : null
                    }
                    <form onSubmit={(e) => {
                        e.preventDefault();

                        if (bait === true) {
                            return
                        };
                        if (rgpd === false) {
                            setError('Vous n\'avez pas accepté les rgpd. Veuillez cocher la case si vous accepté l\'envoie de vos données pour traiter le formulaire');
                            return
                        };

                        if (name === '' || phone === '' || email === '' || gender === '' || message === '') {
                            setError('Un champ obligatoire n\'a pas été rempli. Vérifier d\'avoir rempli tout les champs avant d\'envoyer le formulaire');
                            return;
                        }
                        setError('');
                        setLoading(true)
                        axios({
                            method: 'post',
                            url: 'https://api.digitalcominfluence.fr/app.php',
                            data: {
                                name: name,
                                phone: phone,
                                email: email,
                                gender: gender,
                                title: title,
                                message: message,
                                budget: budget,
                                rgpd: rgpd,
                                bait: bait,
                                sendForm: 'sendForm'
                            },
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                        })
                            .then(response => {
                                navigate('/');
                                localStorage.setItem('success', response.data);
                                setLoading(false)
                            })
                            .catch(error => {
                                console.error('Erreur lors de la récupération des données :', error);
                            });
                    }}>
                        <div className="contact-form-item">
                            <input onChange={(e) => setName(e.target.value)} type="text" id='lastname' defaultValue={name} placeholder='Perrine Delaroche' />
                            <label htmlFor="lastname">Nom / Prénom</label>
                        </div>
                        <div className="contact-form-item">
                            <input onChange={(e) => setPhone(e.target.value)} type="text" id='phone' defaultValue={phone} placeholder='0667365028' />
                            <label htmlFor="phone">Numéro de téléphone</label>
                        </div>
                        <div className="contact-form-item">
                            <input onChange={(e) => setEmail(e.target.value)} type="email" id='email' defaultValue={email} placeholder='digitalcominfluence@gmail.com' />
                            <label htmlFor="email">Adresse e-mail</label>
                        </div>
                        <div className="contact-form-item">
                            <select name="gender" id="gender" onChange={(e) => setGender(e.target.value)}>
                                <option disabled selected>-- Séléctionner un genre --</option>
                                <option value="Homme">Homme</option>
                                <option value="Femme">Femme</option>
                                <option value="Autre">Ne pas renseigner</option>
                            </select>
                            <label htmlFor="gender">Genre</label>
                        </div>
                        <div className="contact-form-item">
                            <input onChange={(e) => setTitle(e.target.value)} type="text" id='title' defaultValue={title} placeholder='Demande de devis' />
                            <label htmlFor="title">Titre de la demande<small><em> (optionnel)</em></small></label>
                        </div>
                        <div className="contact-form-item">
                            <input onChange={(e) => setBudget(e.target.value)} type="number" id='budget' defaultValue={budget} placeholder='500' />
                            <label htmlFor="budget">Budget approximatif<small><em> (optionnel)</em></small></label>
                            <svg style={{ marginRight: '30px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#ff9a62" d="M15 18.5c-2.5 0-4.68-1.42-5.76-3.5H15l1-2H8.58c-.05-.33-.08-.66-.08-1s.03-.67.08-1H15l1-2H9.24A6.491 6.491 0 0 1 15 5.5c1.61 0 3.09.59 4.23 1.57L21 5.3A8.955 8.955 0 0 0 15 3c-3.92 0-7.24 2.5-8.5 6H3l-1 2h4.06c-.06.33-.06.66-.06 1s0 .67.06 1H3l-1 2h4.5c1.26 3.5 4.58 6 8.5 6c2.31 0 4.41-.87 6-2.3l-1.78-1.77c-1.13.98-2.6 1.57-4.22 1.57Z" />
                            </svg>
                        </div>
                        <div className="contact-form-item" id='textarea'>
                            <textarea placeholder='Décrivez moi votre demande' name="message" id="message" cols="30" rows="10" onChange={(e) => setMessage(e.target.value)} defaultValue={message}></textarea>
                            <label htmlFor="message">Message</label>
                        </div>
                        <div className='rgpd-container'>
                            <input type="checkbox" name="rgpd" id="rgpd" onClick={(e) => {
                                setRgpd(e.target.checked);
                            }} />
                            <label htmlFor="rgpd">En cochant cette case, j'accepte que mes données soient collectées dans le cadre de ma demande de contact, mais ne soient pas enregistrées dans la base de données conformément au RGPD</label>
                        </div>
                        <input type="checkbox" name="bait" id="bait" onClick={(e) => {
                            setBait(e.target.checked);
                        }} />
                        {
                            error ?
                                <div className="form-error">
                                    <p>{error}</p>
                                </div>
                                : null
                        }
                        {
                            !loading ?
                                <button className='button'>Envoyer</button>
                                : <p className='loading-form'>Envoie en cours..</p>
                        }
                    </form>
                </div>
            </div>
        </section >
    );
};

export default Contact;