import React, {useRef, useEffect} from 'react';
import { NavLink } from 'react-router-dom';


const HomeService = () => {

    const elementRefService1 = useRef(null);
    const elementRefService2 = useRef(null);
    const elementRefService3 = useRef(null);



    useEffect(() => {
        const observerService1 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateService1');
                    } else {
                        // entry.target.classList.remove('animateService1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        const observerService2 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateService1');
                    } else {
                        // entry.target.classList.remove('animateService1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        const observerService3 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateService1');
                    } else {
                        // entry.target.classList.remove('animateService1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );

        if (elementRefService1.current) {
            observerService1.observe(elementRefService1.current);
        }
        if (elementRefService2.current) {
            observerService2.observe(elementRefService2.current);
        }
        if (elementRefService3.current) {
            observerService3.observe(elementRefService3.current);
        }


        return () => {

            if (elementRefService1.current) {
                observerService1.unobserve(elementRefService1.current);
            }
            if (elementRefService2.current) {
                observerService2.unobserve(elementRefService2.current);
            }
            if (elementRefService3.current) {
                observerService3.unobserve(elementRefService3.current);
            }
        };
    }, []);
    return (
        <section className="services">
                        <h2 id="services">Mes services</h2>
                        <div className="services-list">
                            <article ref={elementRefService1}>
                                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/calendar.svg" alt="Illustration d'un calendrier éditorial" />
                                <h3>Mise en place d'un calendrier éditorial</h3>
                                <div className="service-item">
                                    <ul>
                                        <li>Définition du volume et du rythme de contenus</li>
                                        <li>Programmation de la publication des contenus</li>
                                    </ul>
                                </div>
                                {/* <p className='price'>15€ TTC / heure</p> */}
                                <NavLink to="/contact" className="devis-button">Demander un devis</NavLink>
                            </article>
                            <article ref={elementRefService2}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/moments.svg" alt="Illustration d'un appareil photo" />
                                <h3>Création de contenu</h3>
                                <div className="service-item">
                                    <ul>
                                        <li>Prise de photos et de vidéos</li>
                                        <li>Création de visuels sur Canva</li>
                                        <li>Montage vidéo avec Capcut</li>
                                    </ul>
                                </div>
                                {/* <p className='price'>15€ TTC / heure</p> */}
                                <NavLink to="/contact" className="devis-button devis-button-orange">Demander un devis</NavLink>
                            </article>
                            <article ref={elementRefService3}>
                                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/posting.svg" alt="Illustration d'un calendrier éditorial" />
                                <h3>Publications et animations des réseaux sociaux</h3>
                                <div className="service-item">
                                    <ul>
                                        <li>Création de stories</li>
                                        <li>Création et gestions de posts</li>
                                        <li>Gestions des commentaires</li>
                                        <li>Répondre aux messages</li>
                                    </ul>
                                </div>
                                {/* <p className='price'>15€ TTC / heure</p> */}
                                <NavLink to="/contact" className="devis-button">Demander un devis</NavLink>
                            </article>
                        </div>
                        <NavLink to="/contact">
                            <div className="button">
                                <p>Me contacter !</p>
                            </div>
                        </NavLink>
                    </section>
    );
};

export default HomeService;