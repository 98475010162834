import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setMenuOpen(false);
    }, [location.pathname]);

    return (
        <header>
            <nav className='nav'>
                <div className="container">
                    <div className='menu' onClick={() => {
                        if (menuOpen) {
                            setMenuOpen(false);
                        } else {
                            setMenuOpen(true);
                        }
                    }}>
                        <button aria-label="Instagram de DigitalComInfluence" name='menu burger' className={menuOpen ? 'activeMenu menu-burger' : 'menu-burger'}><span></span></button>
                    </div>
                    <NavLink to="/">
                        <figure>
                            <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/logo-digitalcominfluence.svg" alt="Logo DigitalComInfluence - Perrine Delaroche" />
                        </figure>
                    </NavLink>
                    <ul className={menuOpen ? 'activeUl' : null}>
                        <li>
                            <NavLink to="/" className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }>Accueil</NavLink>
                        </li>
                        <li>
                            <a href="/#services">Services</a>
                        </li>
                        <li>
                            <NavLink to="/a-propos" className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }>A propos</NavLink>
                        </li>
                        <li>
                            <NavLink to="/blog" className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }>Blog</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact" className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }
                            >Contact</NavLink>
                        </li>
                    </ul>
                </div >
            </nav>
            <div className="banner">
                <picture>
                    <source media="(max-width: 768px)" srcSet="https://digitalcominfluence.fr/img/banniere700.webp" />
                    <source media="(max-width: 1350px)" srcSet="https://digitalcominfluence.fr/img/banniere1300.webp" />
                    <source media="(min-width: 1351px)" srcSet="https://digitalcominfluence.fr/img/banniere2000.webp" />

                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/banniere.webp" alt="Bannière DigitalComInfluence" />
                </picture>

            </div>
        </header >
    );
};

export default Header;