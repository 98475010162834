import React, {useEffect, useState} from 'react';
import axios from 'axios';

const ViewsList = () => {
    const [views, setViews] = useState([]);
    const [viewsPerPage, setViewsPerPage] = useState(views);
    const [page, setPage] = useState("Toutes")

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?getAllViews=true`)
            .then(response => {
                setViews(response.data);
                setViewsPerPage(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])

    const ViewsFiltered = (value) => {
        if (value === 'all') {
            setViewsPerPage(views);
            return;
        }
        const viewsArray = [...views];

        const filteredArray = viewsArray.filter((item) => item.page === value)

        setViewsPerPage(filteredArray);
    }
    return (
        <section className="views_list">
            <p>Nombre de vues totales : <strong>{viewsPerPage.length} vues sur la page {page === "/" ? "Accueil" : page === "all" ? "Toutes les pages" : page}</strong></p>
            <div>
                <label htmlFor="filter">Filtrer par page</label>
                <select name="filter" id="filter" onChange={(e) => {
                    ViewsFiltered(e.target.value);
                    setPage(e.target.value);
                }}>
                    <option value="all">Toutes</option>
                    <option value="/">Accueil</option>
                    <option value="/blog">Blog</option>
                    <option value="/a-propos">A propos</option>
                    <option value="/contact">Contact</option>
                </select>
            </div>
            <table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Localisation</th>
                    <th>OS/Navigateur</th>
                    <th>Page</th>
                </tr>
                </thead>
                <tbody>
                {
                    viewsPerPage.map((item, i) => (
                        <tr key={i}>
                            <td>{item.date}</td>
                            <td>{item.location}</td>
                            <td>{item.os}</td>
                            <td>{item.page}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </section>
    );
};

export default ViewsList;