import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Layout from '../layouts/Layout';
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Legales from '../pages/Legales';
import NotFound from '../pages/NotFound';
import Blog from '../pages/Blog';
import Article from '../pages/Article';
import DashboardLayout from '../layouts/DashboardLayout';
import AddArticle from '../pages/admin/AddArticle';
import Dashboard from '../pages/admin/Dashboard';
import AdminArticlesList from '../pages/admin/AdminArticlesList';
import UpdateArticle from '../pages/admin/UpdateArticle';
import Login from '../pages/Login';
import NewsLetterList from '../pages/admin/NewsLetterList';
import Views from '../pages/admin/Views';

const index = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path='/a-propos' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/blog'>
                        <Route index element={<Blog />} />
                        <Route path='article/:slug/:id' element={<Article />} />
                    </Route>
                    <Route path='/mentions-legales' element={<Legales />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path='/admin' >
                    <Route index element={<Login />} />
                    {/* <Route path='dashboard' element={<Dashboard />} /> */}
                    <Route path='blog' element={<DashboardLayout />}>
                        <Route index element={<AdminArticlesList />} />
                        <Route path='ajouter-article' element={<AddArticle />} />
                        <Route path='modifier-article/:id' element={<UpdateArticle />} />
                        <Route path='newsletter' element={<NewsLetterList />} />
                        <Route path='views' element={<Views />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default index;