import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomePresentation from '../components/HomePresentation';
import AboutCM from '../components/AboutCM';
import HomeService from '../components/HomeService';
import HomeStep from '../components/HomeStep';
import HomeOpinions from '../components/HomeOpinions';

const Home = () => {
    const [successMessage, setSuccessMessage] = useState('');

    const deleteMessage = () => {
        setSuccessMessage('')
        localStorage.removeItem('success')
    }
    useEffect(() => {
        if (localStorage.getItem('success')) {
            setSuccessMessage(localStorage.getItem('success'));
        }
    }, [successMessage]);


    return (
        <section className="home">
            <Helmet>
                <title>Community Manager - Gestion et Animation de Réseaux Sociaux | DigitalComInfluence</title>
                <meta name="description" content="Découvrez DigitalComInfluence, alias Perrine Delaroche, Community Manager Freelance basée dans l'Yonne (89) en Bourgogne. Spécialisée dans la gestion et la création de contenu sur les réseaux sociaux !" />
            </Helmet>
            {
                successMessage ?
                    <div className='successMessage' onClick={() => deleteMessage()}><p>{successMessage}</p><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="currentColor" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2zm0 26C9.4 28 4 22.6 4 16S9.4 4 16 4s12 5.4 12 12s-5.4 12-12 12z" /><path fill="currentColor" d="M21.4 23L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z" /></svg></div>
                    : null
            }
            <div className="container">
                <HomePresentation />
                <AboutCM />
                <section className="third-section" >
                    <HomeService />
                    <HomeStep />
                    <HomeOpinions />
                </section >
            </div >
        </section >
    );
};

export default Home;