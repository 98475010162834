import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <section className='about' id='top'>
            <Helmet>
                <title>À propos de DigitalComInfluence | Votre partenaire pour l'influence numérique</title>
                <meta name="description" content="Découvrez qui se cache derrière DigitalComInfluence et explorez mes réalisations. Peut-être que cette rencontre nous mènera vers une collaboration exceptionnelle dans le monde de l'influence numérique !" />
            </Helmet>
            <div className="container">
                <div className="identity">
                    <figure>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/head2.webp" alt="Photo de Perrine Delaroche" />
                    </figure>
                    <h1>Perrine Delaroche, <span>Community Manager Freelance</span></h1>
                </div>
                <div className="about-text">
                    <p>Moi, c'est Perrine, j’ai 25 ans et je suis maman d’une petite fille. J’habite dans la jolie ville de <strong>Sens
                        dans l'Yonne(89)</strong>, en <strong>Bourgogne</strong>.
                        Je suis passionnée depuis toujours par les <strong>réseaux sociaux</strong>. Je me suis intéressée au métier de <strong>Community Manager</strong> lors du premier confinement.</p>
                    <p>L’idée de pouvoir <strong>créer du visuel</strong>, <strong>gérer une communauté</strong> sur les <strong>réseaux</strong> et <strong>faire vivre une marque</strong> sur les <strong>réseaux sociaux</strong> m’a beaucoup attiré. J’ai donc décidé de me lancer en <strong>freelance</strong> et de créer <strong>DigitalComInfluence</strong> pour vous aider à <strong>briller sur les réseaux sociaux</strong>.</p>
                    <p>J’ai travaillé pour une web télé dans l’Yonne, La Télé de l’Yonne, où j’ai travaillé sur des <strong>reportages vidéo</strong>, au <strong>montage vidéo</strong> et à l’installation d’un plateau télé, ainsi qu’à la <strong>gestion des réseaux sociaux</strong>.</p>
                    <p>J’ai été responsable de la création d’une page <strong>Instagram</strong> et <strong>LinkedIn</strong> pour une application web sur le cinéma, Foxinema.
                        Je suis <strong>Community Manager</strong> sur <strong>Instagram</strong>, <strong>Facebook</strong> et <strong>LinkedIn</strong>.</p>
                    <p>Je saurai mettre ma <strong>créativité</strong> et ma <strong>curiosité</strong> au <strong>service de votre entreprise</strong>.</p>
                </div>
                <div className="realisations">
                    <h2>Quelques-unes de mes créations</h2>
                    <div className="realisations-list">
                        <article className="realisation">
                            <figure>
                                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/realisations/affiche-job-dating2.webp" alt="Affiche d'un job dating" />
                            </figure>
                            <div className="infos">
                                <h3>Affiche pour un job dating du Greta 89</h3>
                                <p>Affiche créée lors d'un exercice de communication. L'objectif était de créer une affiche pour promouvoir un Jobdating, et la plus belle affiche serait choisie. Mon affiche a donc été affichée dans les locaux pour faire la promotion de l'événement.</p>
                            </div>
                        </article>
                        <article className="realisation">
                            <figure>
                                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/realisations/bijoux2.webp" alt="Post Instagram pour une entreprise de bijoux" />
                            </figure>
                            <div className="infos">
                                <h3>Post Instagram pour une entreprise de bijoux</h3>
                                <p>Après avoir postulé dans cette entreprise, la personne chargée du recrutement m'a demandé de montrer ce que je savais faire et de créer un post Instagram utilisant leurs bijoux afin d'en faire la promotion à l'occasion de la nouvelle année.</p>
                            </div>
                        </article>
                        <article className="realisation">
                            <figure>
                                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/realisations/lamesure2.webp" alt="Montage vidéo pour le journal de La Télé de l'Yonne" />
                            </figure>
                            <div className="infos">
                                <h3>Montage vidéo pour le journal de La Télé de l'Yonne</h3>
                                <p>Pendant mon stage dans la Web TV, j'ai réalisé le montage d'un reportage pour la Boutique 'La Mesure' d’Auxerre, tourné par l’équipe de La Télé de L’Yonne. Ce reportage a ensuite été diffusé lors du JT du 9 juin 2023.</p>
                                <a style={{ color: 'black', textDecoration: 'underline', fontWeight: 'bold' }} href="https://www.youtube.com/watch?v=85J3fI1l9qY" target='_blank' rel="noreferrer">Voir le reportage</a>
                            </div>
                        </article>
                        <article className="realisation">
                            <figure>
                                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/realisations/foxinema2.webp" alt="Création de la page Instagram de Foxinema" />
                            </figure>
                            <div className="infos">
                                <h3>Création de la page Instagram de Foxinema</h3>
                                <p>Foxinéma est une application web qui permet de discuter de ses films et séries préférés. J’ai donc créé la page Instagram et LinkedIn afin d’annoncer l’arrivée prochaine de l’appli. Projet mis en pause.</p>
                            </div>
                        </article>
                    </div>
                </div>
            </div >
        </section >

    );
};

export default About;