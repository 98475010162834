import React from 'react';
import axios from 'axios';

const DeleteArticle = ({article_id, article_title,setUpArticle}) => {
    const DeleteArticle = (id) => {
        if(window.confirm(`L'article ${article_title} n°${article_id} va être supprimé ! Cette action est définitive, êtes vous sur ?`)){
            axios.get(`https://api.digitalcominfluence.fr/app.php?deleteArticle=true&article_id=${id}`)
            .then(response => {
                setUpArticle(true)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }
    return (
        <p onClick={(e) => DeleteArticle(article_id)}>Supprimer l'article</p>
    );
};

export default DeleteArticle;