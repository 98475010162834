import React, {useRef, useEffect} from 'react';
import { NavLink } from 'react-router-dom';

const AboutCM = () => {
    const elementRef2 = useRef(null);


    useEffect(() => {

        const observer2 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate2');
                    } else {
                        // entry.target.classList.remove('animate2');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        if (elementRef2.current) {
            observer2.observe(elementRef2.current);
        }
       
        return () => {
         
            if (elementRef2.current) {
                observer2.unobserve(elementRef2.current);
            }
        };
    }, []);
    return (
        <section className="second-section">
                    <h2>Et sinon, c'est quoi un Community Manager ?</h2>
                    <figure>
                        <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/online.svg" alt="Illustration Online" />
                    </figure>
                    <div className="second-section-text animated-element2" ref={elementRef2}>
                        <p>Le <strong>Community Manager</strong> (plus communément appelé <strong>CM</strong>) est un
                            <strong> gestionnaire des réseaux sociaux d'une entreprise</strong>, d'une <strong>marque</strong> ou
                            d'une <strong>personnalité</strong>. Il assure la <strong>modération des différents comptes</strong> et
                            a pour mission de faire vivre l'identité de son client sur les différents
                            <strong> canaux de communication</strong>.</p>
                        <p>
                            Il s'occupe aussi de la <strong>création des posts</strong>, des <strong>visuels</strong> en accord avec
                            la <strong>charte graphique</strong> de votre <strong>entreprise</strong>.</p>
                        <p>C'est un <strong>travail en équipe</strong> afin que vous vous sentiez comme chez
                            vous sur les <strong>réseaux</strong>.</p>
                        <p>Que ce soit pour une collaboration de courte durée ou pour du long terme,
                            je vous propose mes services. <strong>Création d'affiches</strong>, création d'une <strong>
                                page
                                Instagram
                            </strong>, <strong>Facebook</strong> ou <strong>LinkedIn</strong>, mise en place d'un <strong>calendrier éditorial</strong>,
                            n'hésitez pas à me contacter!</p>
                        <p>Alors, ça vous dit que l'on travaille ensemble?</p>
                        <p className='second-section-citation'><em><small>"Le community management, c'est l'art de transformer des inconnus en amis, des amis en clients, et des clients en ambassadeurs." - Jay Baer</small></em></p>
                    </div>
                    <NavLink to="/a-propos">
                        <div className="button">
                            <p>Venez me découvrir !</p>
                        </div>
                    </NavLink>
                    <figure>
                        <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/deal.svg" alt="Illustration d'un deal professionnel" />
                    </figure>
                </section>
    );
};

export default AboutCM;