import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Layout = () => {
    const location = useLocation();

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?setNewView=true&page=${location.pathname}`)
            .then(response => {
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [location.pathname]);

    return (
        <>
            <Header />
            <ScrollToTop />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    );
};

export default Layout;