import React from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <section className='not-found'>
            <Helmet>
                <title>404 Not Found.. - DigitalComInfluence</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container">
                <h1>Oups.. La page que vous recherchez n'a pas été trouvé. <NavLink to={'/'}>Retourner à l'accueil</NavLink></h1>
                <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/notfound.svg" alt="" />
            </div>
        </section>
    );
};

export default NotFound;