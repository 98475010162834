import axios from 'axios';
import React, { Suspense, useState } from 'react';

const Newsletter = () => {

    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    return (
        <div className="newsletter">
            <div className="container">
                <h3>Je souhaite m'abonner à la newsletter afin d'être informer à la sortie d'un nouvel article !</h3>
                <p>Saisissez votre adresse e-mail pour vous inscrire à la newsletter</p>
                {
                    success ?
                        <p className='newsletter_success'>{success}</p>
                        : error ? <p className='newsletter_error'>{error}</p>
                            : null
                }
                <div className="newsletter_container">
                    <figure>
                        <img src="https://digitalcominfluence.fr/img/illustrations/newsletter.svg" alt="Illustration d'une newsletter" width="100%" height="100%" />
                    </figure>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        axios({
                            method: 'post',
                            url: 'https://api.digitalcominfluence.fr/app.php',
                            data: {
                                email: email,
                                newsletter: 'newsletter'
                            },
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        })
                            .then(function (response) {
                                if (response.data.success) {
                                    setSuccess(response.data.success);
                                }
                                if (response.data.error) {
                                    setError(response.data.error);
                                }
                            })
                            .catch(function (error) {
                                console.error('Erreur :', error);
                            });
                    }}>
                        <div>
                            <input type="email" name='email' placeholder='digitalcominfluence@gmail.com' onChange={(e) => setEmail(e.target.value)} />
                            <button>M'inscrire</button>
                        </div>
                        <p>En soumettant ce formulaire, j'accepte que mon adresse e-mail soit enregistrée dans le cadre de mon inscription à la newsletter.</p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;