import React from 'react';
import { Helmet } from 'react-helmet';

const Legales = () => {
    return (
        <section className="legales">
            <Helmet>
                <title>Mentions légales - DigitalComInfluence</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container" style={{ padding: '10px' }}>
                <h1>Mentions Légales - DigitalComInfluence.fr</h1>
                <section>
                    <h2>Informations légales</h2>
                    <p>Raison sociale : DigitalComInfluence</p>
                    <p>Statut juridique : Auto-entrepreneur</p>
                    <p>Adresse : 2 rue de l'Epée 89100 Sens</p>
                    <p>Téléphone : 06 67 36 50 28</p>
                    <p>E-mail : digitalcominfluence@gmail.com</p>
                    <p>Numéro SIRET : 97992062600012</p>
                    <p>Directeur de la publication : Perrine Delaroche</p>
                </section>

                <section>
                    <h2>Hébergement du site</h2>
                    <p>Le site est hébergé par : o2switch</p>
                    <p>Adresse de l'hébergeur : Chem. des Pardiaux, 63000 Clermont-Ferrand</p>
                </section>

                <section>
                    <h2>Propriété intellectuelle</h2>
                    <p>Ce site et son contenu sont la propriété de Perrine Delaroche - DigitalComInfluence. Tous les éléments du site, y compris les textes, images, vidéos, logos, et autres contenus, sont protégés par les lois sur la propriété intellectuelle.</p>
                    <p>Toute reproduction, distribution, ou utilisation non autorisée du contenu de ce site est interdite.</p>
                </section>

                <section>
                    <h2>Données personnelles</h2>
                    <p>Les informations collectées sur ce site sont destinées à Perrine Delaroche - DigitalComInfluence en tant que responsable du traitement. Elles sont collectées dans le but de gérer les formulaires de contact afin de répondre aux demandes des utilisateurs.</p>
                    <p>Vous disposez d'un droit d'accès, de rectification, et de suppression des données vous concernant. Pour exercer ces droits, veuillez nous contacter à l'adresse digitalcominfluence@gmail.com.</p>
                </section>
            </div>
        </section>
    );
};

export default Legales;