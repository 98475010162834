import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Article = () => {
    const articleId = useParams().id;
    const [article, setArticle] = useState([]);

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?getArticle=true&article_id=${articleId}`)
            .then(response => {
                setArticle(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])
    return (
        <div className="container">
            <Helmet>
                <title>{`${article.title}`} | DigitalComInfluence</title>
                <meta name="description" content="Explorez notre blog pour des articles informatifs et variés sur le monde du digital, des nouvelles technologies, du web et bien d'autres sujets passionnants !" />
            </Helmet>
            <NavLink to='/blog' className="button-to-blog">Revenir à la liste des articles</NavLink>
            <section className='article'>
                <h1>{article.title}</h1>
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img width="100%" height="100%" src={article.image} alt={`Image de l'article ${article.title}`} />
                <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
                {
                    article.source ?
                        <small><em> <p>Source : {article.source}</p></em></small>
                        : null
                }
                <small><em><p>{article.created_at}</p></em></small>
            </section>
        </div>
    );
};

export default Article;