import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const ArticlesList = () => {
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);
    const articlesPerPage = 6;
    const [resetArticles, setResetArticle] = useState(false);
    const [articlesLoader, setArticlesLoader] = useState(true);

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?getAllArticles=true`)
            .then(response => {
                setArticles(response.data);
                setResetArticle(false);
                setArticlesLoader(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [resetArticles]);
    useEffect(() => {

    }, []);
    const getPaginatedArticles = () => {
        const startIndex = (page - 1) * articlesPerPage;
        const endIndex = startIndex + articlesPerPage;
        return articles.slice(startIndex, endIndex);
    };

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);
        if (searchValue === "") {
            setResetArticle(true);
        }
        const filteredResults = articles.filter((article) =>
            article.title.toLowerCase().includes(searchValue.toLowerCase())
        );

        setArticles(filteredResults);
    };

    return (
        <div className="container articles_list">
            <div className="search_article">
                <p>Rechercher un article</p>
                <input type="text" onChange={handleSearch} value={searchTerm} placeholder='Mots clé...' />
            </div>
            <section className="articles">
                {
                    articlesLoader ?
                        <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg"  xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 0 0"  space="preserve">
                            <circle fill="#FF9A62" stroke="none" cx="6" cy="50" r="6">
                                <animateTransform
                                    attributeName="transform"
                                    dur="1s"
                                    type="translate"
                                    values="0 15 ; 0 -15; 0 15"
                                    repeatCount="indefinite"
                                    begin="0.1" />
                            </circle>
                            <circle fill="#FF9A62" stroke="none" cx="30" cy="50" r="6">
                                <animateTransform
                                    attributeName="transform"
                                    dur="1s"
                                    type="translate"
                                    values="0 10 ; 0 -10; 0 10"
                                    repeatCount="indefinite"
                                    begin="0.2" />
                            </circle>
                            <circle fill="#FF9A62" stroke="none" cx="54" cy="50" r="6">
                                <animateTransform
                                    attributeName="transform"
                                    dur="1s"
                                    type="translate"
                                    values="0 5 ; 0 -5; 0 5"
                                    repeatCount="indefinite"
                                    begin="0.3" />
                            </circle>
                        </svg>
                        :
                        articles.length > 0 ?
                            getPaginatedArticles().map((item, i) => (
                                <article key={i}>
                                    <figure>
                                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                        <img width="100%" height="100%" src={item.image} alt={`Image de l'article ${item.title}`} />
                                    </figure>
                                    <h3>{item.title}</h3>
                                    {/* <p>{item.content.substring(0, 200)} ...</p> */}
                                    <NavLink className='button' to={`/blog/article/${item.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')}/${item.id}`}>Voir l'article</NavLink>
                                </article>
                            ))
                            : <p>Aucun articles pour le moment !</p>
                }
            </section>
            {
                articles.length > 6 ?
                    <div className="pagination">
                        <button onClick={() => setPage(page - 1)} disabled={page === 1}>Précédent</button>
                        <span>Page {page}</span>
                        <button onClick={() => setPage(page + 1)} disabled={page * articlesPerPage >= articles.length}>Suivant</button>
                    </div>
                    : null
            }
        </div>
    );
};

export default ArticlesList;
