import React from 'react';
import ViewsList from '../../components/admin/ViewsList';

const Views = () => {
    return (
        <section className="views container">
            <h1>Statistiques de vues</h1>
            <ViewsList />
        </section>
    );
};

export default Views;