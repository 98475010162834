import React, {useRef} from 'react';

const Vcard = () => {

    const linkRef = useRef(null);
    const createVCard = (firstName, lastName, email, cellPhone, url) => {
        return [
            'BEGIN:VCARD',
            'VERSION:3.0',
            `N:${lastName};${firstName};;;`,
            `FN:${firstName} ${lastName}`,
            `EMAIL:${email}`,
            `TEL;TYPE=CELL:${cellPhone}`,
            `URL:${url}`,
            'END:VCARD'
        ].join('\n');
    };

    const handleClick = () => {
        // Créer une nouvelle vCard
        const vCardString = createVCard(
            'Perrine Delaroche',
            'DigitalComInfluence',
            'digitalcominfluence@gmail.com',
            '06.67.36.50.28',
            'https://digitalcominfluence.fr'
        );

        const vCardBlob = new Blob([vCardString], { type: 'text/vcard' });

        linkRef.current.href = URL.createObjectURL(vCardBlob);
        linkRef.current.click();
    };

    return (
        <>
            <button onClick={handleClick} className="button">Télécharger ma vCard</button>
            <a style={{display:"none"}} ref={linkRef} href="https://digitalcominfluence.fr" download="Perrine-Delaroche.vcf">Télécharger ma vCard</a>
        </>
    );
};

export default Vcard;