import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Helmet } from 'react-helmet';

const UpdateArticle = () => {
    const articleId = useParams().id;
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState([]);
    const [source, setSource] = useState([]);
    const navigate = useNavigate();

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setContent(data);
    };

    const [article, setArticle] = useState([]);

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?getArticle=true&article_id=${articleId}`)
            .then(response => {
                setArticle(response.data);
                setTitle(response.data.title)
                setContent(response.data.content)
                setSource(response.data.source)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])

    return (
        <div className="container">
            <Helmet>
                <title>Modifier un article - DigitalComInfluence</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section className="add-article">
                <h1>Modifier l'article <strong>{article.title}</strong></h1>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    axios({
                        method: 'post',
                        url: 'https://api.digitalcominfluence.fr/app.php',
                        data: {
                            article_id: article.id,
                            title: title,
                            content: content,
                            source: source,
                            image: image,
                            updateArticle: 'updateArticle'
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(function (response) {
                            navigate('/admin/blog');
                        })
                        .catch(function (error) {
                            console.error('Erreur :', error);
                        });

                }}>
                    <div>
                        <label htmlFor="title">Titre de l'article</label>
                        <input type="text" name="title" id="title" onChange={(e) => setTitle(e.target.value)} required defaultValue={article.title} />
                    </div>
                    <div>
                        <label htmlFor="title">Contenu de l'article</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={article.content}
                            onChange={handleEditorChange}
                        />
                    </div>
                    {
                        article.image ?
                            <>
                                <p>Image actuelle de l'article</p>
                                <img width='300px' src={article.image} alt="" />
                            </>
                            : null
                    }
                    <div>
                        <label htmlFor="image">Changer d'image</label>
                        <input type="file" name="image" id="image" onChange={(e) => setImage([...e.target.files])} />
                    </div>
                    <div>
                        <label htmlFor="source">Source de l'article <small><em>(Optionnel)</em></small></label>
                        <input type="text" name="source" id="source" onChange={(e) => setSource(e.target.value)} defaultValue={article.source} />
                    </div>
                    <button className='button'>Modifier l'article</button>
                </form>
            </section>
        </div>
    );
};

export default UpdateArticle;