import React, {useRef, useEffect} from 'react';

const HomePresentation = () => {

    const elementRef1 = useRef(null);


    useEffect(() => {
        const observer1 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Logique d'animation pour le premier élément
                        entry.target.classList.add('animate');
                    } else {
                        // Réinitialisez l'animation lorsque l'élément n'est plus visible
                        // entry.target.classList.remove('animate');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );

        if (elementRef1.current) {
            observer1.observe(elementRef1.current);
        }
        
        return () => {
            if (elementRef1.current) {
                observer1.unobserve(elementRef1.current);
            }
        };
    }, []);

    return (
        <section className="first-section">
                    <h1>DigitalComInfluence - Community Manager Freelance</h1>
                    <h2>Pourquoi faire appel à un Community Manager ?</h2>
                    <div className="first-section-text">
                        <p><strong>Community Manager</strong> depuis peu, je propose aux <strong>entreprises</strong> mes
                            services quant à la <strong>gestion</strong> de leurs <strong>réseaux sociaux</strong>.</p>
                        <p>Confier la <strong>gestion</strong> de ses <strong>réseaux sociaux</strong> à un <strong>Community Manager</strong>,
                            c’est être assuré d’avoir la <strong>communication</strong> adéquate auprès de ses <strong>clients</strong>.</p>
                        <p>Le <strong>Community Manager</strong> est la vitrine de <strong>l’entreprise</strong> qu'il représente.</p>
                    </div>
                    <div className="first-section-list animated-element" ref={elementRef1}>
                        <ul>
                            <li>
                                <h3>Une communication adéquate</h3>
                                <p>Le <strong>Community Manager</strong> peut s’engager sur le long terme afin d’assurer le <strong>suivi communicatif</strong> de votre entreprise. <br />
                                    <strong>DigitalComInfluence</strong> s’adapte donc à la <strong>charte visuelle</strong> de votre <strong>entreprise</strong>. Que ce soit pour faire la <strong>promotion d'un produit</strong>, mettre en avant <strong>des services</strong>, je suis là pour vous aiguiller quant à la meilleure façon de le faire et quel <strong>réseau </strong>
                                    sera le plus approprié pour votre <strong>stratégie de communication</strong>.</p>
                            </li>
                            <li>
                                <h3>Cela permet de faire connaissance avec son audience</h3>
                                <p>Vous aurez certainement établi un <strong>persona</strong> afin d’adapter <strong>vos produits </strong>
                                    ou <strong>vos offres</strong>. Mais il ne faut pas oublier que derrière l'écran se trouvent de vraies personnes. Il faut savoir où et quand leur parler, en s’adaptant à leur langage. Le <strong>Community Manager</strong> est un véritable caméléon ! <strong>DigitalComInfluence</strong> va chercher à connaître <strong>votre public</strong> afin de mieux cerner leur personnalité et ainsi vous aider à affiner <strong>votre communication</strong>.
                                </p>
                            </li>
                            <li>
                                <h3>Et apporte une tranquillité</h3>
                                <p>En me confiant la <strong>gestion de vos réseaux sociaux</strong>, vous aurez tout le temps nécessaire pour vous consacrer à votre activité. <strong>Création de visuels</strong>, <strong>animation de vos réseaux sociaux</strong>, <strong>mise en place d'un calendrier éditorial</strong>, je suis là pour vous épauler.</p>
                            </li>
                        </ul>
                    </div>
                </section>
    );
};

export default HomePresentation;