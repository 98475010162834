import axios from 'axios';
import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AddArticle = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState([]);
    const [source, setSource] = useState('');
    const navigate = useNavigate();

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setContent(data);
    };

    return (
        <div className="container">
             <Helmet>
                <title>Ajouter un article - DigitalComInfluence</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section className="add-article">
                <h1>Ajouter un article</h1>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    axios({
                        method: 'post',
                        url: 'https://api.digitalcominfluence.fr/app.php',
                        data: {
                            title: title,
                            content: content,
                            source: source,
                            image: image,
                            addArticle: 'addArticle'
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(function (response) {
                            navigate('/admin/blog');
                        })
                        .catch(function (error) {
                            console.error('Erreur :', error);
                        });

                }}>
                    <div>
                        <label htmlFor="title">Titre de l'article</label>
                        <input type="text" name="title" id="title" onChange={(e) => setTitle(e.target.value)} required />
                    </div>
                    <div>
                        <label htmlFor="title">Contenu de l'article</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={content}
                            onChange={handleEditorChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="image">Image de l'article</label>
                        <input type="file" name="image" id="image" onChange={(e) => setImage([...e.target.files])} required />
                    </div>
                    <div>
                        <label htmlFor="source">Source de l'article <small><em>(Optionnel)</em></small></label>
                        <input type="text" name="source" id="source" onChange={(e) => setSource(e.target.value)} />
                    </div>
                    <button className='button'>Ajouter l'article</button>
                </form>
            </section>
        </div>
    );
};

export default AddArticle;