import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Outlet, useLocation } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import AdminHeader from '../components/admin/AdminHeader';
import { Helmet } from 'react-helmet';

const DashboardLayout = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    useEffect(() => {
        axios
            .get(`https://api.digitalcominfluence.fr/app.php?checkToken=checkToken&id=${id}&token=${token}&t=${Date.now()}`)
            .then((response) => {
                if (!response.data) {
                    navigate('/admin');
                } else {
                    if (location.pathname === '/') {
                        navigate('/admin/blog');
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [location.pathname]);

    return (
        <section className="admin">
            <Helmet>
                <title>Administration - DigitalComInfluence</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <AdminHeader />
            <ScrollToTop />
            <Outlet />
        </section>
    );
};

export default DashboardLayout;