import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Login = () => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [userName, setuserName] = useState('');
    const [password, setPassword] = useState('');

    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    useEffect(() => {
        axios.get(`https://api.digitalcominfluence.fr/app.php?checkToken=checkToken&id=${id}&token=${token}&t=${Date.now()}`)
            .then(response => {
                if (!response.data) {
                    navigate('/admin');
                    return
                } else {
                    navigate('/admin/blog');
                    return
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])

    return (

        <div className="login">
            <Helmet>
                <title>Connexion - DigitalComInfluence</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="card">
                <h2>Connexion</h2>
                {
                    error !== '' ?
                        <p style={{ color: 'red' }}>{error}</p>
                        : null
                }
                <form action="" onSubmit={(e) => {
                    e.preventDefault();

                    axios.get(`https://api.digitalcominfluence.fr/app.php?adminLogin=adminLogin&username=${userName}&password=${password}`)
                        .then(response => {
                            if (response.data === 'Nom d\'utilisateur ou mot de passe incorrect.') {
                                setError('Les identifiants sont incorrects !')
                            } else {
                                const token = response.data.token;
                                localStorage.setItem('token', token);
                                localStorage.setItem('username', response.data.username);
                                localStorage.setItem('id', response.data.id);
                                navigate('/admin/blog');
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });

                }}>
                    <label htmlFor="name">Nom</label>
                    <input type="text" id='name' name='username' onChange={(e) => setuserName(e.target.value)} />
                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" id='password' onChange={(e) => setPassword(e.target.value)} />
                    <button>Me connecter</button>
                </form>
            </div>
        </div>
    );
};

export default Login;