import React, {useRef, useEffect} from 'react';


const HomeStep = () => {

    const elementRefStep1 = useRef(null);
    const elementRefStep2 = useRef(null);
    const elementRefStep3 = useRef(null);
    const elementRefStep4 = useRef(null);


    useEffect(() => {
        const observerStep1 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateStep1');
                    } else {
                        // entry.target.classList.remove('animateStep1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        const observerStep2 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateStep1');
                    } else {
                        // entry.target.classList.remove('animateStep1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        const observerStep3 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateStep1');
                    } else {
                        // entry.target.classList.remove('animateStep1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        const observerStep4 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateStep1');
                    } else {
                        // entry.target.classList.remove('animateStep1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        if (elementRefStep1.current) {
            observerStep1.observe(elementRefStep1.current);
        }
        if (elementRefStep2.current) {
            observerStep2.observe(elementRefStep2.current);
        }
        if (elementRefStep3.current) {
            observerStep3.observe(elementRefStep3.current);
        }
        if (elementRefStep4.current) {
            observerStep4.observe(elementRefStep4.current);
        }

        return () => {

            if (elementRefStep1.current) {
                observerStep1.unobserve(elementRefStep1.current);
            }
            if (elementRefStep2.current) {
                observerStep2.unobserve(elementRefStep2.current);
            }
            if (elementRefStep3.current) {
                observerStep3.unobserve(elementRefStep3.current);
            }
            if (elementRefStep4.current) {
                observerStep4.unobserve(elementRefStep4.current);
            }
        };
    }, []);
    return (
        <section className="steps">
            <h2>Les différentes étapes d'une collaboration</h2>
            <div className="steps-list">
                <article className="step" ref={elementRefStep1}>
                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/interview.svg" alt="Illustration d'un rendez-vous professionnel" />
                    <h3>Rencontre</h3>
                    <ul>
                        <li>Vous m'expliquez votre entreprise et vos besoins afin que je puisse m'adapter au mieux</li>
                        <li>Explication de mon intervention</li>
                    </ul>
                </article>
                <article className="step" ref={elementRefStep2}>
                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/stud.svg" alt="Illustration d'une personne qui étudie" />
                    <h3>Étude</h3>
                    <ul>
                        <li>Mise en place d'une veille concurrentielle afin de définir le plan d'action</li>
                        <li>On identifie ensemble vos points forts ainsi que les points à améliorer</li>
                    </ul>
                </article>
                <article className="step" ref={elementRefStep3}>
                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/ideas.svg" alt="Illustration d'une personne qui a des idées" />
                    <h3>Proposition</h3>
                    <ul>
                        <li>Après avoir défini les différentes actions à mettre en place, je vous expose ma stratégie</li>
                        <li>Présentation du devis</li>
                    </ul>
                </article>
                <article className="step" ref={elementRefStep4}>
                    <img width="100%" height="100%" src="https://digitalcominfluence.fr/img/illustrations/walking.svg" alt="Illustration de deux personnes qui marchent" />
                    <h3>On y va ?</h3>
                    <ul>
                        <li>Une fois les actions validées et le devis accepté, je passe à l'action : modération des pages, gestion, création de visuels, etc.</li>
                    </ul>
                </article>
            </div>
        </section>
    );
};

export default HomeStep;