import React, {useRef, useEffect} from 'react';

const HomeOpinions = () => {
    const elementRefOpinions1 = useRef(null);
    const elementRefOpinions2 = useRef(null);
    const elementRefOpinions3 = useRef(null);


    useEffect(() => {
        const observerOpinions1 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateService1');
                    } else {
                        // entry.target.classList.remove('animateService1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        const observerOpinions2 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateService1');
                    } else {
                        // entry.target.classList.remove('animateService1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );
        const observerOpinions3 = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animateService1');
                    } else {
                        // entry.target.classList.remove('animateService1');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            }
        );

        if (elementRefOpinions1.current) {
            observerOpinions1.observe(elementRefOpinions1.current);
        }
        if (elementRefOpinions2.current) {
            observerOpinions2.observe(elementRefOpinions2.current);
        }
        if (elementRefOpinions3.current) {
            observerOpinions3.observe(elementRefOpinions3.current);
        }
        return () => {

            if (elementRefOpinions1.current) {
                observerOpinions1.unobserve(elementRefOpinions1.current);
            }
            if (elementRefOpinions2.current) {
                observerOpinions2.unobserve(elementRefOpinions2.current);
            }
            if (elementRefOpinions3.current) {
                observerOpinions3.unobserve(elementRefOpinions3.current);
            }

        };
    }, []);
    return (
        <section className="opinions">
                        <h4>- Ils ont travaillé avec moi et ils en parlent -</h4>
                        <div className="opinions-list">
                            <article ref={elementRefOpinions1}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" /></svg>
                                <p>Une Community manager au top qui a su comprendre mes besoins et mes envies pour mes posts Instagram. Perrine a su créer des designs en accord avec la charte graphique de mon application et est très professionnelle. Je recommande sans hésitation si vous avez besoin de gérer vos réseaux !</p>
                                <h5>Mehdi Raposo, Développeur Web</h5>
                            </article>
                            <article ref={elementRefOpinions2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" /></svg>
                                <p>J'ai fait appel aux services de Perrine pour faire développer mes réseaux. Elle est très professionnelle. Elle a su trouver des stratégies pour remédier à mes problèmes.
                                    Je vous la recommande à 100%</p>
                                <h5>Elodie Trotard, Patissière indépendante</h5>
                            </article>
                            <article ref={elementRefOpinions3}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054c.062-.372.166-.703.31-.992c.145-.29.331-.517.559-.683c.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992a4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" /></svg>
                                <p>Très à l'écoute, Perrine sait s'adapter aux différentes situations et besoins afin de produire un travail approprié. Sa bonne humeur fait d'elle une collègue très agréable, elle reste tout de même professionnelle et toujours investie. Curieuse et avisée, je suis persuadée que Perrine n'a pas fini d'évoluer.</p>
                                <h5>Suzie Cailin, salariée de "La Télé de l'Yonne"</h5>
                            </article>
                        </div>
                    </section>
    );
};

export default HomeOpinions;